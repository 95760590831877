import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Platform, NavController, NavParams } from '@ionic/angular';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { TranslateConfigService } from '../../translate-config.service';


@Component({
  selector: 'app-stillalive',
  templateUrl: './stillalive.page.html',
  styleUrls: ['./stillalive.page.scss'],
})
export class StillalivePage implements OnInit {

// tslint:disable-next-line: new-parens
// public navParams = new NavParams;
adaRecipientList: any;
autoRecipientList: any;
userId: string;
displayName = '';
docId = '';
inputConfirmDay = 1;
inputNotifyDay = 1;
nextConfirmTime: any;
adaLists = [];
autoLists = [];
dd: any;
mm: any;
mm2: any;
yy: any;

// FOR LANGUAGE
selectedLanguage: string;
userData = [];

  constructor(public navCtrl: NavController, // public navParams: NavParams,
              private location: Location,
              public firestore: AngularFirestore,
              public afAuth: AngularFireAuth,
              private translateConfigService: TranslateConfigService,
              private platform: Platform,
  ) {
    // GET PARAMETERS FROM EXTERNAL URL ?n=
    // this.userId = this.navParams.get('userId');
    this.userId = this.platform.getQueryParam('n');
    // console.log('user:', this.userId);
    // console.log(this.platform.getQueryParam('p'));
  }

  ngOnInit() {

    this.afAuth.auth.signInWithEmailAndPassword('dummy@i-handslab.com', 'dum246').then(async () => {

    });

  }


  ionViewWillEnter() {

  }


  ionViewDidEnter() {

            // GET SELECTED LANGUAGE
            const lang = this.firestore.collection('users').doc(this.userId).valueChanges();
            // tslint:disable-next-line: deprecation
            lang.subscribe(items => {
                this.userData.push(items);
                this.displayName = this.userData[0].firstName;
                this.selectedLanguage = this.userData[0].language;
                this.translateConfigService.setLanguage(this.selectedLanguage);

            });

            // CHECK IF AUTOSETUP FILE EXISTED OR NOT
            const temp = this.firestore.collection('users').doc(this.userId).collection('Autosetup').valueChanges();
            // tslint:disable-next-line: deprecation
            temp.subscribe(items => {
                  if (items.length > 0) {
                      this.docId = items[0].DocId;
                      this.inputConfirmDay = items[0].ConfirmDay;
                      this.inputNotifyDay = items[0].NotifyDay;
                  }

                  // UPDATE AUTOSETUP FILE
                  const notificationTime = new Date();
                  notificationTime.setHours(notificationTime.getHours() + (24 * (this.inputConfirmDay)));
                  const userDoc = this.firestore.doc<any>('users/' + this.userId).collection('Autosetup');
                  userDoc.doc(this.docId).update({
                      NextNotification: notificationTime.toUTCString()
                  });
                  console.log('autosetp:', notificationTime);
                  // this.nextConfirmTime = notificationTime.toISOString();
                  this.nextConfirmTime = notificationTime;

                  this.dd = this.nextConfirmTime.getDate();
                  this.mm = this.nextConfirmTime.toLocaleString('default', { month: 'long' });
                  this.mm2 = this.nextConfirmTime.getMonth() + 1;
                  this.yy = this.nextConfirmTime.getFullYear();

            }); // end check



            // STORE ADA RECIPIENT INFO
            this.adaRecipientList = this.firestore.doc<any>('users/' + this.userId).collection('Adarecipient');
            this.firestore.doc('users/' + this.userId).collection('Adarecipient').ref
            .get()
            .then(async (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                      this.adaLists.push(doc.data());
                  });

                  // UPDATE ADA RECIPIENT NOTIFICATION TIME
                  for (const adalist of this.adaLists) {
                          const recnotificationTime = new Date();
                          recnotificationTime.setHours(recnotificationTime.getHours() + (24 * (this.inputConfirmDay + adalist.NotifyDay)));
                          this.adaRecipientList.doc(adalist.DocId).update({
                              NextNotification: recnotificationTime.toUTCString(),
                          });
                          // console.log('ada recipient:', recnotificationTime);
                  } // end update recipient

            });

            // STORE AUTO RECIPIENT INFO
            this.autoRecipientList = this.firestore.doc<any>('users/' + this.userId).collection('Autorecipient');
            this.firestore.doc('users/' + this.userId).collection('Autorecipient').ref
            .get()
            .then(async (Snapshot) => {
                  Snapshot.forEach((doc) => {
                      this.autoLists.push(doc.data());
                  });

                  // UPDATE AUTO RECIPIENT NOTIFICATION TIME
                  for (const list of this.autoLists) {
                  const recnotificationTime = new Date();
                  recnotificationTime.setHours(recnotificationTime.getHours() + (24 * (this.inputConfirmDay + list.NotifyDay)));
                  this.autoRecipientList.doc(list.DocId).update({
                      NextNotification: recnotificationTime.toUTCString(),
                  });
                  // console.log('auto recipient:', recnotificationTime);
                  } // end update recipient
            });
            // console.log('adalist:', this.adaLists);
            // console.log('autolist:', this.autoLists);
}


goBack() {
  this.location.back();
}


goPageMenu() {
  this.navCtrl.navigateRoot('/afterdeath');
}




}
