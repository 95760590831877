import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common'; // for browser issue

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
// import { SQLite } from '@ionic-native/sqlite/ngx';
// FOR FIREBASE DATABASE //
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from 'angularfire2/firestore';
// FOR STORAGE
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { StillalivePageModule } from './pages/stillalive/stillalive.module';


// FOR TRANSLATION
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateConfigService } from './translate-config.service';
// import { Deeplinks } from '@ionic-native/deeplinks/ngx';

// FOR SERVICE
import { UserService } from './user.service';

// FOR IN-APP PURCHASE
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  // return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


// FOR FIREBASE DATABASE //
export const firebaseConfig = {
 apiKey: 'AIzaSyB_P_K_d_VRueTLzwhk1b2JP5wsyyVNnm4',
 authDomain: 'mywill-8321d.firebaseapp.com',
 databaseURL: 'https://mywill-8321d.firebaseio.com',
 projectId: 'mywill-8321d',
 storageBucket: 'mywill-8321d.appspot.com',
 messagingSenderId: '524377245250',
 appId: '1:524377245250:web:ab36319874346c5c504fdb',
 measurementId: 'G-CP6K2EH7K6'
};


@NgModule({
  declarations: [
    AppComponent,


    // LoginPage,
    // RegisterPage,
    // ResetPasswordPage
  ],

  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
   // FOR FIREBASE DATABASE //
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,

    AngularFireModule.initializeApp(environment.firebase, 'my-app-name'), // imports firebase/app needed for everything
    AngularFireStorageModule, // imports firebase/storage only needed for storage features

    FormsModule,
    ReactiveFormsModule,
    StillalivePageModule,


    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (LanguageLoader),
        deps: [HttpClient]
      }
    })

    ],
  entryComponents: [

  ],
  providers: [
    StatusBar,
    SplashScreen,
    TranslateConfigService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy }, // for browser version issue
    File,
    FileOpener,
    EmailComposer,
    // Deeplinks,
    InAppPurchase2,
    UserService,
    // SQLite,

  ],

  bootstrap: [AppComponent]
})
export class AppModule {}


