//
// Finally did not use this Service because Service only load once.
// Only suitable for data that do NOT change
//

import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { TranslateConfigService } from 'src/app/translate-config.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {


  userId = '';
  userData = [];
  displayName = '';
  email = '';
  // FOR LANGUAGE
  selectedLanguage: string;

  constructor(public navCtrl: NavController,
              public firestore: AngularFirestore,
              public afAuth: AngularFireAuth,
              private translateConfigService: TranslateConfigService,
              ) {

                // GET USER ID INFO
                this.afAuth.authState.subscribe(user => {
                  if (user) {

                      this.userId = user.uid;
                      const temp = this.firestore.collection('users').doc(this.userId).valueChanges();
                      // tslint:disable-next-line: deprecation
                      temp.subscribe(items => {
                          this.userData.push(items);
                          this.displayName = this.userData[0].displayName;
                          this.email = this.userData[0].email;
                      });
                  }
                });

              }


  refreshData() {
     // GET USER ID INFO
     this.afAuth.authState.subscribe(user => {
          if (user) {
              this.userId = user.uid;
              const temp = this.firestore.collection('users').doc(this.userId).valueChanges();
              // tslint:disable-next-line: deprecation
              temp.subscribe(items => {
                  this.userData.push(items);
                  this.selectedLanguage = this.userData[0].language;
                  this.translateConfigService.setLanguage(this.selectedLanguage);
              });
          }
      });
  }


}
