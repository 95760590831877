import { Component } from '@angular/core';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HomePage } from './home/home.page';
import { AngularFireAuth } from 'angularfire2/auth';
import { MenuPage } from './pages/menu/menu.page';
import {TranslateService} from '@ngx-translate/core';
import { Platform, NavController } from '@ionic/angular';
// import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { StillalivePage } from './pages/stillalive/stillalive.page';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {

  rootPage: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private afAuth: AngularFireAuth,
    public translate: TranslateService,
    protected navController: NavController,
    // protected deeplinks: Deeplinks
  ) {

/*    // FOR DEEPLINKS
    this.platform.ready().then(() => {
      this.deeplinks.routeWithNavController(this.navController, {
        '/about-us': HomePage,
        '/stillalive/:userId': StillalivePage
      }).subscribe((match) => {
        // match.$route - the route we matched, which is the matched entry from the arguments to route()
        // match.$args - the args passed in the link
        // match.$link - the full link data
        console.log('Successfully matched route', match);
      },
      (nomatch) => {
        // nomatch.$link - the full link data
        console.error('Got a deeplink that didn\'t match', nomatch);
      });
    }); */

    // TELL JSON FILE NAMES AND SET DEFAULT LANGUAGE
    translate.addLangs(['en', 'chinese-T', 'chinese-S']);
    translate.setDefaultLang('en');
    // const language = translate.getBrowserLang();
    // translate.use(language);


    this.initializeApp();

    const authObserver = this.afAuth.authState.subscribe(user => {
      if (user) {
          this.rootPage = MenuPage;
          authObserver.unsubscribe();
      } else {
          this.rootPage = MenuPage;
          authObserver.unsubscribe();
      }
    });
    }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }


}


