import { Component, OnInit } from '@angular/core';
import { NavController, AlertController, Events } from '@ionic/angular';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { TranslateConfigService } from '../../translate-config.service';
import { UserService } from '../../user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {

  userId = '';
  Versions = [];
  selectVersion = '';
  subMenu = false;

  // FOR DELETE BUTTON
  BeneficiaryTaskList: any;
  BeneficiaryList: any;
  TestatorTaskList: any;
  TestatorList: any;
  ExecutorTaskList: any;
  ExecutorList: any;
  LandedPropertyTaskList: any;
  LandedPropertyList: any;
  LandedBeneficiaryTaskList: any;
  LandedBeneficiaryList: any;
  MovableTaskList: any;
  MovableList: any;
  MovableBeneficiaryTaskList: any;
  MovableBeneficiaryList: any;
  ResidueBeneficiaryTaskList: any;
  ResidueBeneficiaryList: any;
  WillContentTaskList: any;
  WillContentList: any;
  SimplePropertyTaskList: any;
  SimplePropertyList: any;
  SimpleBeneficiaryTaskList: any;
  SimpleBeneficiaryList: any;
  BankTaskList: any;
  BankList: any;
  BankBeneficiaryTaskList: any;
  BankBeneficiaryList: any;


  bene = [];
  testator = [];
  executor = [];
  landedProperty = [];
  landedBeneficiary = [];
  movable = [];
  movableBeneficiary = [];
  residueBeneficiary = [];
  willContent = [];
  simpleProperty = [];
  simpleBeneficiary = [];
  bank = [];
  bankBeneficiary = [];

  // FOR LANGUAGE
  selectedLanguage: string;
  userData = [];
  userName: any;
  userEmail: any;
  expiryTime: any;
  premium1 = false;
  premium2 = false;
  vip = false;
  VIP = '';
  willExpiryTime: any;
  accessExpiryTime: any;
  admin = false;

  // THIS PAGES IS FOR ANOTHER SIDE MENU METHOD //
  pages = [
      {
        title: 'Home',
        url: '/menu',
        icon: 'home'
      },
      {
        title: 'Beneficiary',
        url: '/beneficiary',
        icon: 'people'
      },
      {
        title: 'Preview / Export',
        url: '/export',
        icon: 'download'
      },
      {
        title: 'Important Legal Advice',
        url: '/workshop',
        icon: 'list'
      },
      {
        title: 'Upload (Video/Voice/File)',
        url: '/recipientfile',
        icon: 'cloud-upload'
      },
      {
        title: 'Last Words Setup',
        url: '/autosetup',
        icon: 'timer'
      },
      {
        title: 'Upgrade to Premium User',
        url: '/menu',
        icon: 'rocket'
      },
      {
        title: 'Setting',
        icon: 'settings',
        children: [
            {
                title: 'Profile',
                url: '/profile',
                icon: 'person'
            },
            {
                title: 'Will Version',
                url: '/selectcontent',
                icon: 'globe'
            },
            {
                title: 'Language',
                url: '/selectcontent',
                icon: 'globe'
            },
            {
                title: 'User Agreement',
                url: '/agreement',
                icon: 'checkbox-outline'
            },
        ]
      },
  ];



  constructor(private navCtrl: NavController, public afAuth: AngularFireAuth,
              public firestore: AngularFirestore, public alertCtrl: AlertController,
              private translateConfigService: TranslateConfigService,
              private translate: TranslateService,
              public userService: UserService,
              public events: Events) {

                  /* user service load only once even after logout, not workable
                  this.userName = this.userService.displayName;
                  this.userEmail = this.userService.email; */
              }


  ngOnInit() {}


  ionViewWillEnter() {
                // GET USER ID INFO AND SETUP DOCUMENT(TABLE) NAME
                this.afAuth.authState.subscribe(user => {
                  if (user) {
                      this.userId = user.uid;
                      const temp = this.firestore.collection('users').doc(this.userId).valueChanges();
                      // tslint:disable-next-line: deprecation
                      temp.subscribe(items => {
                          this.Versions.push(items);
                          this.selectVersion = this.Versions[0].version;
                          this.userData.push(items);
                          this.VIP = this.userData[0].vip;
                          this.selectedLanguage = this.userData[0].language;
                          this.expiryTime = new Date(this.userData[0].allAccessExpiry);
                          this.userName = this.userData[0].firstName;
                          this.userEmail = this.userData[0].email;
                          this.willExpiryTime = new Date(this.userData[0].willExpiry);
                          this.accessExpiryTime = new Date(this.userData[0].allAccessExpiry);

                          this.translateConfigService.setLanguage(this.selectedLanguage);

                          // CEHCK IF ADMIN
                          if (this.userEmail === 'info.mywill.team@gmail.com') {
                              this.admin = true;
                          }

                          // CEHCK IF VIP
                          if (this.VIP === 'Yes') {
                            this.vip = true;
                          }

                          // CHECK IF PREMIUM USER OR NOT
                          const currentTime = new Date();
                          if (this.willExpiryTime !== null && this.willExpiryTime >= currentTime) {
                              this.premium1 = true;
                          } else if (this.accessExpiryTime !== null && this.accessExpiryTime >= currentTime) {
                              this.premium2 = true;
                          }
                      });

                      // FOR DELETE BUTTON
                      this.BeneficiaryTaskList = this.firestore.doc<any>('users/' + this.userId).collection('Beneficiary').valueChanges();
                      this.BeneficiaryList = this.firestore.doc<any>('users/' + this.userId).collection('Beneficiary');
                      this.TestatorTaskList = this.firestore.doc<any>('users/' + this.userId).collection('Testator').valueChanges();
                      this.TestatorList = this.firestore.doc<any>('users/' + this.userId).collection('Testator');
                      this.ExecutorTaskList = this.firestore.doc<any>('users/' + this.userId).collection('Executor').valueChanges();
                      this.ExecutorList = this.firestore.doc<any>('users/' + this.userId).collection('Executor');
                      this.LandedPropertyTaskList = this.firestore.doc<any>('users/' + this.userId).collection('LandedProperty',
                      ref => ref.orderBy('LandedPropertyOrder', 'asc')).valueChanges();
                      this.LandedPropertyList = this.firestore.doc<any>('users/' + this.userId).collection('LandedProperty');
                      this.LandedBeneficiaryTaskList = this.firestore.doc<any>('users/' + this.userId).collection('LandedBeneficiary',
                      ref => ref.orderBy('LandedPropertyOrder', 'asc')).valueChanges();
                      this.LandedBeneficiaryList = this.firestore.doc<any>('users/' + this.userId).collection('LandedBeneficiary');
                      this.MovableTaskList = this.firestore.doc<any>('users/' + this.userId).collection('MovableProperty',
                      ref => ref.orderBy('MovablePropertyOrder', 'asc')).valueChanges();
                      this.MovableList = this.firestore.doc<any>('users/' + this.userId).collection('MovableProperty');
                      this.MovableBeneficiaryTaskList = this.firestore.doc<any>('users/' + this.userId).collection('MovableBeneficiary',
                      ref => ref.orderBy('MovablePropertyOrder', 'asc')).valueChanges();
                      this.MovableBeneficiaryList = this.firestore.doc<any>('users/' + this.userId).collection('MovableBeneficiary');
                      this.ResidueBeneficiaryTaskList = this.firestore.doc<any>('users/' + this.userId).collection('ResidueBeneficiary',
                      ref => ref.orderBy('ResidueEstateOrder', 'asc')).valueChanges();
                      this.ResidueBeneficiaryList = this.firestore.doc<any>('users/' + this.userId).collection('ResidueBeneficiary');
                      this.WillContentTaskList = this.firestore.doc<any>('users/' + this.userId).collection('WillContent',
                      ref => ref.orderBy('ParaOrder', 'asc')).valueChanges();
                      this.WillContentList = this.firestore.doc<any>('users/' + this.userId).collection('WillContent');
                      this.SimplePropertyTaskList = this.firestore.doc<any>('users/' + this.userId).collection('SimpleProperty',
                      ref => ref.orderBy('SimplePropertyOrder', 'asc')).valueChanges();
                      this.SimplePropertyList = this.firestore.doc<any>('users/' + this.userId).collection('SimpleProperty');
                      this.SimpleBeneficiaryTaskList = this.firestore.doc<any>('users/' + this.userId).collection('SimpleBeneficiary',
                      ref => ref.orderBy('SimplePropertyOrder', 'asc')).valueChanges();
                      this.SimpleBeneficiaryList = this.firestore.doc<any>('users/' + this.userId).collection('SimpleBeneficiary');
                      this.BankTaskList = this.firestore.doc<any>('users/' + this.userId).collection('BankMoney',
                      ref => ref.orderBy('BankOrder', 'asc')).valueChanges();
                      this.BankList = this.firestore.doc<any>('users/' + this.userId).collection('BankMoney');
                      this.BankBeneficiaryTaskList = this.firestore.doc<any>('users/' + this.userId).collection('BankBeneficiary',
                      ref => ref.orderBy('BankOrder', 'asc')).valueChanges();
                      this.BankBeneficiaryList = this.firestore.doc<any>('users/' + this.userId).collection('BankBeneficiary');

                      this.BeneficiaryList.ref.get().then(async (querySnapshot) => {
                           querySnapshot.forEach((items) => {
                            this.bene.push(items.data());
                           });
                      });
                      this.TestatorList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.testator.push(items.data());
                          });
                      });
                      this.ExecutorList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.executor.push(items.data());
                          });
                      });
                      this.LandedPropertyList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.landedProperty.push(items.data());
                          });
                      });
                      this.LandedBeneficiaryList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.landedBeneficiary.push(items.data());
                          });
                      });
                      this.MovableList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.movable.push(items.data());
                          });
                      });
                      this.MovableBeneficiaryList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.movableBeneficiary.push(items.data());
                          });
                      });
                      this.ResidueBeneficiaryList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.residueBeneficiary.push(items.data());
                          });
                      });
                      this.WillContentList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.willContent.push(items.data());
                          });
                      });
                      this.SimplePropertyList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.simpleProperty.push(items.data());
                          });
                      });
                      this.SimpleBeneficiaryList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.simpleBeneficiary.push(items.data());
                          });
                      });
                      this.BankList.ref.get().then(async (querySnapshot) => {
                        querySnapshot.forEach((items) => {
                        this.bank.push(items.data());
                        });
                      });
                      this.BankBeneficiaryList.ref.get().then(async (querySnapshot) => {
                          querySnapshot.forEach((items) => {
                          this.bankBeneficiary.push(items.data());
                          });
                      });

                      // end delete button

                  } // end if user
                 });

  }



  listSubMenu() {
      if (this.subMenu) {
          this.subMenu = false;
      } else {
          this.subMenu = true;
      }
  }

  goPageAdmin() {
    this.navCtrl.navigateRoot('/adminmenu');
  }

  goPageBeneficiary() {
      this.navCtrl.navigateRoot('/beneficiary');
  }

  goPagePreview() {
    this.navCtrl.navigateRoot('/export');
  }

  goPageWorkshop() {
    this.navCtrl.navigateRoot('/workshop');
  }

  goPageUpload() {
    const currentTime = new Date();
    if (this.expiryTime !== null && this.expiryTime >= currentTime) {
        this.navCtrl.navigateRoot('/autorecipient');
    } else {
        this.navCtrl.navigateRoot('/autofrontpage');
    }
  }

  goPageAutosetup() {
    this.navCtrl.navigateRoot('/autosetup');
  }


  goPageAda() {
    const currentTime = new Date();
    if (this.expiryTime !== null && this.expiryTime >= currentTime) {
        this.navCtrl.navigateRoot('/afterdeath');
    } else {
        this.navCtrl.navigateRoot('/adafrontpage');
    }
  }


  goPageAutoemail() {
    const currentTime = new Date();
    if (this.expiryTime !== null && this.expiryTime >= currentTime) {

        this.navCtrl.navigateRoot('/autosetup');

/*        // CHECK IF AUTOSETUP FILE EXISTED OR NOT
        const temp = this.firestore.collection('users').doc(this.userId).collection('Autosetup').valueChanges();
            // tslint:disable-next-line: deprecation
        temp.subscribe(items => {
                    if (items.length > 0) {
                        this.navCtrl.navigateRoot('/autoemail');
                    } else {
                        this.setupAlert();
                        this.navCtrl.navigateRoot('/menu');
                    }
             }); // end check */

    } else {
        this.navCtrl.navigateRoot('/autofrontpage');
    }
  }


  async goPageDelete() {
      const alert = await this.alertCtrl.create({
        cssClass: 'myalert',
        // header: 'ALERT',
        // message: 'Are you sure to delete Will data?',
        header: this.translate.instant('alert.header1'),
        subHeader: this.translate.instant('alert.message1'),
        message: this.translate.instant('alert.message1a'),
        buttons: [{ text: this.translate.instant('alert.buttoncancel'), role: 'cancel' },
                  { text: this.translate.instant('alert.buttonyes'), handler: () => {

                    this.deleteWillContent();
                    this.deleteDoneAlert();
                }
              }]
      });
      await alert.present();

  }


  deleteWillContent() { // DELETE ALL WILLCONTENT
      if (this.bene.length > 0) {
        for (const member of this.bene) {
          this.BeneficiaryList.doc(member.BeneficiaryId).delete();
        }
      }
      if (this.testator.length > 0) {
        for (const item of this.testator) {
          this.TestatorList.doc(item.DocId).delete();
        }
      }
      if (this.executor.length > 0) {
        for (const item of this.executor) {
          this.ExecutorList.doc(item.DocId).delete();
        }
      }
      if (this.landedProperty.length > 0) {
        for (const item of this.landedProperty) {
          this.LandedPropertyList.doc(item.LandedPropertyId).delete();
        }
      }
      if (this.landedBeneficiary.length > 0) {
        for (const item of this.landedBeneficiary) {
          this.LandedBeneficiaryList.doc(item.DocId).delete();
        }
      }
      if (this.movable.length > 0) {
        for (const item of this.movable) {
          this.MovableList.doc(item.MovablePropertyId).delete();
        }
      }
      if (this.movableBeneficiary.length > 0) {
        for (const item of this.movableBeneficiary) {
          this.MovableBeneficiaryList.doc(item.DocId).delete();
        }
      }
      if (this.residueBeneficiary.length > 0) {
        for (const item of this.residueBeneficiary) {
          this.ResidueBeneficiaryList.doc(item.DocId).delete();
        }
      }
      if (this.willContent.length > 0) {
        for (const item of this.willContent) {
          this.WillContentList.doc(item.DocId).delete();
        }
      }
      if (this.simpleProperty.length > 0) {
        for (const item of this.simpleProperty) {
          this.SimplePropertyList.doc(item.SimplePropertyId).delete();
        }
      }
      if (this.simpleBeneficiary.length > 0) {
        for (const item of this.simpleBeneficiary) {
          this.SimpleBeneficiaryList.doc(item.DocId).delete();
        }
      }
      if (this.bank.length > 0) {
        for (const item of this.bank) {
          this.BankList.doc(item.BankId).delete();
        }
      }
      if (this.bankBeneficiary.length > 0) {
        for (const item of this.bankBeneficiary) {
          this.BankBeneficiaryList.doc(item.DocId).delete();
        }
      }

  }


  async deleteDoneAlert() {
        const alert = await this.alertCtrl.create({
                cssClass: 'myalert',
                // header: 'ALERT',
                // subHeader: 'Subtitle',
                // message: 'Will data was deleted',
                header: this.translate.instant('alert.header1'),
                message: this.translate.instant('alert.message2'),
                buttons: ['OK']
        });
        alert.present();

  }


  goPageUpgrade() {
    this.navCtrl.navigateRoot('/upgrade');
  }

  goPageProfile() {
    this.navCtrl.navigateRoot('/profile');
  }

  goPageSelectcontent() {
    this.navCtrl.navigateRoot('/selectcontent');
  }

  goPageLanguage() {
    this.navCtrl.navigateRoot('/language');
  }

  goPageQuestions() {
    this.navCtrl.navigateRoot('/questions');
  }

  goPageAgreement() {
    this.navCtrl.navigateRoot('/agreement');
  }

  goPageWill() {   // ALSO NEED TO UPDATE BENEFICIARY PAGE
      if (this.selectVersion === 'hkEnglishSimple') {
          this.navCtrl.navigateRoot('/hkenglishsimple');
      } else if (this.selectVersion === 'hkChineseSimple') {
          this.navCtrl.navigateRoot('/hkchinesesimple');
      } else if (this.selectVersion === 'ChinaSimple') {
          this.navCtrl.navigateRoot('/v-chinasimple');
      } else if (this.selectVersion === 'SingaporeSimple') {
          this.navCtrl.navigateRoot('/v-singaporesimple');
      } else if (this.selectVersion === 'MalaysiaSimple') {
          this.navCtrl.navigateRoot('/v-malaysiasimple');
      } else if (this.selectVersion === 'UKSimple') {
          this.navCtrl.navigateRoot('/v-uksimple');

      } else if (this.selectVersion === 'hkEnglish') {
          this.checkUpgradeStatus('/hkenglish');
      } else if (this.selectVersion === 'hkChinese') {
          this.checkUpgradeStatus('/hkchinese');
      } else if (this.selectVersion === 'China') {
          this.checkUpgradeStatus('/v-china');
      } else if (this.selectVersion === 'Singapore') {
          this.checkUpgradeStatus('/v-singapore');
      } else if (this.selectVersion === 'Malaysia') {
          this.checkUpgradeStatus('/v-malaysia');
      } else if (this.selectVersion === 'UK') {
          this.checkUpgradeStatus('/v-uk');

      } else {
          this.selectWillAlert();
      }
  }


  checkUpgradeStatus(page) {
      const currentTime = new Date();
      if (this.accessExpiryTime !== '' && this.accessExpiryTime >= currentTime) {
          this.navCtrl.navigateRoot(page);
      } else {
          if (this.willExpiryTime !== '' && this.willExpiryTime >= currentTime) {
              this.navCtrl.navigateRoot(page);
          } else {
              this.premiumAlert();
          }
      }
  }


  async setupAlert() {
        const alert = await this.alertCtrl.create({
                cssClass: 'myalert',
                // header: 'ALERT',
                // subHeader: 'Subtitle',
                // message: 'Please setup After-Death-Arrangement first',
                header: this.translate.instant('alert.header1'),
                message: this.translate.instant('alert.message3'),
                buttons: ['OK']
        });
        alert.present();

  }


  async premiumAlert() {
      const alert = await this.alertCtrl.create({
              cssClass: 'myalert',
              // header: 'ALERT',
              // subHeader: 'This will version is exclusive to Premium User',
              // message: 'Please go to Settings and select other Simple Versions',
              header: this.translate.instant('alert.header1'),
              subHeader: this.translate.instant('alert.message4'),
              message: this.translate.instant('alert.message5'),
              buttons: ['OK']
      });
      alert.present();

  }


  async selectWillAlert() {
      const alert = await this.alertCtrl.create({
              cssClass: 'myalert',
              // header: 'ALERT',
              // subHeader: 'No Will Selected',
              // message: 'Please go back and select Will version',
              header: this.translate.instant('alert.header1'),
              subHeader: this.translate.instant('alert.message6'),
              message: this.translate.instant('alert.message7'),
              buttons: ['OK']
      });
      alert.present();

  }



  async logout() {
          const alert = await this.alertCtrl.create({
            cssClass: 'myalert',
            // header: 'ALERT',
            // message: 'Are you sure to logout?',
            header: this.translate.instant('alert.header1'),
            message: this.translate.instant('alert.message8'),
            buttons: [{ text: this.translate.instant('alert.buttoncancel'), role: 'cancel' },
                      { text: this.translate.instant('alert.buttonyes'), handler: () => {

                        return this.afAuth.auth.signOut().then(authData => {
                          // this.app.getRootNav().setRoot(LoginPage);
                          this.navCtrl.navigateRoot('/login');
                        });

                    }
                  }]
          });
          await alert.present();

  }




}
